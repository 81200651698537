import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { populateProjectData } from './projects';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// const COLOR_LIST = ['#7f00ff', '#ff00ff', '#0000ff', '#007fff', '#00ffff'];
const COLOR_LIST = ['#7f00ff', '#ff00ff', '#0000ff', '#007fff', '#00ffff'];
// const COLOR_LIST = ['#474554', '#4e5a6e', '#517185', '#548996'];
// const COLOR_LIST = ['#8685ef', '#ef85b9', '#eeef85', '#85efbb'];
// const COLOR_LIST = ['#8685ef', '#ef8685', '#85ef86'];
// const COLOR_LIST = ['#8685ef', '#858fef', '#9185ef'];
// const COLOR_LIST = ['#8685ef', '#272645', '#56569a'];
// const COLOR_LIST = ['#8685ef', '#6467cd', '#414aab', '#152f8b', '#00176c'];
// const COLOR_LIST = ['#9781ea', '#ea819f', '#d4ea81', '#81eacb'];
// const COLOR_LIST = ['#8685ef', '#e67ddb', '#ff83b1', '#ffa184', '#ffcc67', '#f9f871'];

let isSmallScreen = window.matchMedia('(max-width: 768px)').matches;
const projectInfo = document.querySelector('.project-info');

// Project description animation

const targetList = document.querySelectorAll('[data-js="reveal"]');

const getArrayRandomValue = (array) =>
  array[Math.floor(Math.random() * array.length)];

export function triggerProjectAnimation() {
  // Use this function outside the trigger if colors are set once
  setupProjectAnimation();

  for (const target of targetList) {
    if (!target.classList.contains('loaded')) {
      target.classList.add('loaded');
      projectInfo.style.pointerEvents = 'auto';
    }
  }
}

function resetProjectAnimation() {
  targetList.forEach((target) => {
    target.classList.remove('loaded');
  });
  projectInfo.style.pointerEvents = 'none';
}

function setupProjectAnimation() {
  for (const target of targetList) {
    const content = target.innerHTML;
    const color =
      'revealColor' in target.dataset
        ? target.dataset.revealColor
        : getArrayRandomValue(COLOR_LIST);
    target.innerHTML = `<span data-reveal="content"><div data-reveal="cover" style="background-color:${color}"></div><span data-reveal="text">${content}</span></span>`;
  }
  projectInfo.style.pointerEvents = 'auto';
}

// Header animation

export function typeWriter(
  { text, elementSelector, typingDelay, cursorStopDelay },
  i,
  fnCallback
) {
  return new Promise((resolve, reject) => {
    if (i < text.length) {
      document.querySelector(elementSelector).innerHTML =
        text.substring(0, i + 1) +
        '<span class="caret-span" aria-hidden="true"></span>';

      setTimeout(function () {
        resolve(
          typeWriter(
            { text, elementSelector, typingDelay, cursorStopDelay },
            i + 3,
            fnCallback
          )
        );
      }, typingDelay);
    } else {
      setTimeout(function () {
        document.querySelector(elementSelector).innerHTML = text;
        if (typeof fnCallback == 'function') {
          fnCallback();
        }
        resolve();
      }, cursorStopDelay);
    }
  });
}

export function headerTextAnimation() {
  const headerText = document.querySelector('.header-1');
  const headerP = document.querySelector('.header-p');

  gsap.fromTo(
    headerText.querySelectorAll('span'), 
    {
        x: 50,
        autoAlpha: 0,
    },
    {
      delay: 0.1,
      duration: 1,
      x: 0,
      autoAlpha: 0.9,
      ease: 'power2.easeOut',
      stagger: {
        from: 'start',
        amount: 0.5,
      }
    }
  );

  gsap.fromTo(
    headerP,
      {
        autoAlpha: 0,
      },
      {
        delay: 1,
        duration: 1,
        autoAlpha: 1,
        ease: 'power2.easeOut',
        onComplete: () => {
          setupArrowsAnimation();
        }
      }
  )
}

// Titles animation

export function setupTitlesAnimation() {
  // const isTouchDevice = detectTouchDevice();
  // console.log('Touch device detected:', detectTouchDevice());
  const navTitles = document.querySelectorAll('.nav-titles');
  let isTouchDevice = false;

  if (ScrollTrigger.isTouch === 1) {
    isTouchDevice = true;
  }
  

  // const mm = gsap.matchMedia();

 
    navTitles.forEach((element) => {
      gsap.fromTo(
        element.querySelectorAll('span'),
        {
          x: 50,
          autoAlpha: 0,
        },
        {
          delay: 0.1,
          duration: 0.3,
          x: 0,
          autoAlpha: 0.9,
          ease: 'power2.easeOut',
          stagger: {
            from: 'start',
            amount: 0.5,
          },
          scrollTrigger: {
            trigger: element,
            start: 'top 100%',
            end: 'bottom 0%',
            scrub: false,

            // toggleActions: isTouchDevice ? 'play complete complete complete' : 'play complete complete reset',
            toggleActions: isTouchDevice ? 'play complete complete complete' : 'play complete complete reset',
            markers: false,
            // invalidateOnRefresh: true, // Ensures recalculation on window resize
            // fastScrollEnd: isTouchDevice, // Fast scroll end for touch devices
            // anticipatePin: isTouchDevice ? 1 : 0, // Anticipate pinning for smoother mobile behavior
     
            // onEnter: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 1 }),
            // onLeave: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 0 }),
            // onEnterBack: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 0 }),
            // onLeaveBack: () => gsap.to(element.querySelectorAll('span'), { autoAlpha: 0 }),
          },
        }
      );
    });
  }


  

  
  // ScrollTrigger.addEventListener('refreshInit', () => ScrollTrigger.refresh());


function detectTouchDevice() {
  return (
    "ontouchstart" in document.documentElement ||
    'ontouchstart' in window || // Most browsers support this
    // navigator.maxTouchPoints > 0 || // For newer, touch-enabled devices
    navigator.msMaxTouchPoints > 0 // For some older Microsoft browsers/devices
  )
}

// Arrow animation

export function setupArrowsAnimation() {
  return new Promise((resolve, reject) => {
    const element = document.querySelector('.arrows-container');

    // Initial fade-in animation
    gsap.fromTo(
      element,
      {
        autoAlpha: 0,
      },
      {
        duration: 1.5,
        autoAlpha: 1,
        ease: 'power2.easeOut',    
      }
    );
    // Scroll activated animation
    gsap.to(
      element,
      {
        duration: 1,
        autoAlpha: 0,
        ease: 'power2.easeOut',
        scrollTrigger: {
          trigger: element,
          start: 'top 40%',
          end: 'top 10%',
          scrub: false,
          toggleActions: 'none none none none',
          markers: false,
          onEnter: () => {
            gsap.to(element, {
              duration: 1,
              autoAlpha: 0,           
              ease: 'power2.easeOut',
            });
          }
        }
      }
    );
    resolve();
  });
}

// Nav links smooth scroll

export function navLinksScroll() {
  document.querySelectorAll('.nav-links li a[href]').forEach((a) => {
    a.addEventListener('click', (e) => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
}

// Detect if a link's href goes to the current page
function getSamePageAnchor(link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if (elem) {
    if (e) e.preventDefault();
    gsap.to(window, { scrollTo: elem });
  }
}

// Projects animation

export function projectsScrollObserve(projectsImages) {
  isSmallScreen = window.matchMedia('(max-width: 768px)').matches;

  if (isSmallScreen) {
    projectsImages.forEach((element) => {
      console.log(element);
      gsap.to(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 150%',
          end: 'top 10%',
          scrub: false,
          toggleActions: 'none none none none',
          markers: false,
          onEnter: () => onEnterProjectAnimation(element),
          onLeave: () => resetProjectAnimation(),
          onEnterBack: () => onEnterProjectAnimation(element),
          onLeaveBack: () => resetProjectAnimation(),
        },
      });
    });
  } else {
    projectsImages.forEach((element) => {
      console.log(element);
      gsap.to(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top 90%',
          end: 'bottom 89%',
          scrub: false,
          toggleActions: 'none none none none',
          markers: false,
          onEnter: () => onEnterProjectAnimation(element),
          onLeave: () => resetProjectAnimation(),
          onEnterBack: () => onEnterProjectAnimation(element),
          onLeaveBack: () => resetProjectAnimation(),
        },
      });
    });
  }
}

function onEnterProjectAnimation(element) {
  populateProjectData(element);
  triggerProjectAnimation();
}

export function gallerySlideshow() {
  const projects = document.querySelectorAll('.project-container');

  projects.forEach((project) => {
    const sections = project.querySelectorAll('.carousel-section');
    const rightBtn = project.querySelector('#right-btn');
    const leftBtn = project.querySelector('#left-btn');
    const container = project.querySelector('.carousel-container');

    let currentIndex = 0;
    const totalSections = sections.length;
    let gap = 20; // Set your gap value here

    // Function to get the width of each section including the gap
    function getSectionWidth() {
      const section = sections[0]; // Get the first section as a reference
      const sectionWidth = section.offsetWidth; // Get the actual width of the section
      return sectionWidth + gap; // Add the gap to account for the space between cards
    }

    // Function to set the gap in CSS
    function setCssGap() {
      container.style.gap = `${gap}px`; // Set the gap property dynamically
    }

    // Call this function to update the CSS gap
    setCssGap();

    // Function to slide to a given index
    function slideTo(index) {
      currentIndex = index;
      if (index < 0) {
        currentIndex = totalSections - 1; // Jump back to the last section when going left from the first
      } else if (index >= totalSections) {
        currentIndex = 0; // Jump back to the first section when going right from the last
      }

      const translateX = -currentIndex * getSectionWidth(); // Calculate the translation value with the gap included

      gsap.to(sections, {
        x: translateX, // Slide by the calculated translateX
        duration: 1,
        ease: 'power2.inOut',
      });
    }

    // Button to slide to the next section (infinite in both directions)
    rightBtn.addEventListener('click', () => {
      slideTo(currentIndex + 1);
    });

    // Button to slide to the previous section
    leftBtn.addEventListener('click', () => {
      slideTo(currentIndex - 1);
    });

    // Add resize event listener to handle responsiveness
    window.addEventListener('resize', () => {
      slideTo(currentIndex); // Recalculate position on window resize
    });

    slideshowReset(slideTo, container);
  });
}


function slideshowReset(slideTo, container) {
  ScrollTrigger.create({
    trigger: container,
    start: 'top bottom',
    end: 'bottom top',
    onLeave: () => {
      slideTo(0);
    },
    onLeaveBack: () => {
      slideTo(0);
    },
  });
}
