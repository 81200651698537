import { setupPortraitAnimation, displayWindowSize } from './animationPortrait';
import { setupProjects } from './projects';
import {
  // typeWriter,
  setupTitlesAnimation,
  setupArrowsAnimation,
  navLinksScroll,
  gallerySlideshow,
  headerTextAnimation
} from './animations';
// import { headerText } from './data/headerData';





// document.addEventListener('DOMContentLoaded', function (event) {
//   typeWriter(headerText[0], 0, function () {
//     typeWriter(headerText[1], 0, function () {
//     typeWriter(headerText[2], 0, 1500);
//     })
//   });
// });

document.addEventListener('DOMContentLoaded', function (event) {
  // typeWriter(headerText[0], 0)
  //   .then(() => typeWriter(headerText[1], 0))
  //   .then(() => typeWriter(headerText[2], 0, 5))
  //   .then(() => setupArrowsAnimation());
  setupPortraitAnimation();
setupTitlesAnimation();
  headerTextAnimation();
  emailLinkCopy();
  navLinksScroll();
  gallerySlideshow();
});


window.addEventListener('resize', () => {
  displayWindowSize();
  setupTitlesAnimation();
  // setupProjects();
  // setupArrowsAnimation();
});

function emailLinkCopy() {
  let emailLink = document.getElementById('email-link');
  let emailText = document.getElementById('email-text');
  let email = 'danielwiejak@gmail.com';
  emailLink.href = `mailto:${email}`;
  emailText.textContent = email;

  emailLink.addEventListener('click', function (event) {
    event.preventDefault();
    let tooltip = document.getElementById('tooltip');
    tooltip.innerHTML = 'Copied!';

    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log('Email address copied to clipboard');
        tooltip.style.visibility = 'visible';
        tooltip.style.opacity = '1';
        setTimeout(() => {
          tooltip.style.visibility = 'hidden';
          tooltip.style.opacity = '0';
        }, 2000);
      })
      .catch((err) => {
        console.log('Could not copy email address: ', err);
      });
  });
}



